<template>
  <section id="aircraft">
    <div class="row">
      <fieldset class="form-group">
        <div class="input-group">
          <select
              class="form-control"
              :class="{ 'is-valid': aircraftType.isValid, 'is-invalid': aircraftType.isValid === false }"
              v-model="aircraftType.value"
              @blur="validateAircraftType()"
          >
            <option value="" disabled hidden>Select aircraft type</option>
            <option v-for="type in aircraftTypes" :value="type.slug">{{ type.readable }}</option>
          </select>
          <input type="text"
              class="form-control"
              :class="{ 'is-valid': registration.isValid, 'is-invalid': registration.isValid === false }"
              placeholder="Enter registration number"
              v-model="registration.value"
              @blur="validateRegistration();lookupResident();"
          />
          <div
              v-if="resident !== null"
              class="form-check form-control">
            <label
                for="include-resident"
                class="form-check-label">
              Resident ID: {{ resident.resident_id }}
            </label>
            <input
                type="checkbox"
                id="include-resident"
                class="form-check-input mt-0"
                v-model="includeResident"
            />
          </div>
          <button class="btn btn-primary" type="button" :disabled="!formValidated || adding" @click="addAircraft()">Add Aircraft</button>
          <label v-if="error" class="control-label">{{error}}</label>
        </div>
      </fieldset>
    </div>
    <div class="row">
      <div class="card col-md-12">
        <div class="card-body">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Aircraft Type</th>
                <th scope="col">Registration</th>
                <th scope="col">Resident ID</th>
                <th scope="col">Added</th>
                <th scope="col">Removed</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="aircraft" v-for="aircraft in aircraft">
                <th scope="row">{{ aircraft.aircraft_id }}</th>
                <td>{{ stringifySlug(aircraft.aircraft_type) }}</td>
                <td>{{ aircraft.registration }}</td>
                <td>{{ aircraft.resident_id }}</td>
                <td>{{ aircraft.created }}</td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm" v-if="!aircraft.deleted" @click="removeAircraft(aircraft)">Remove</button>
                  {{ aircraft.deleted }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import APIService from "@/services/APIService";
import UtilService from "@/services/UtilService";

export default {
  name: "Aircraft",
  data() {
    return {
      aircraftTypes: [],
      aircraft: [],
      resident: null,
      includeResident: false,
      filter: {},
      aircraftType: {
        value: "",
        error: null,
        isValid: undefined
      },
      registration: {
        value: null,
        error: null,
        isValid: undefined
      },
      error: null,
      adding: false
    }
  },
  computed: {
    formValidated() {
      return this.aircraftType.isValid && this.registration.isValid;
    }
  },
  methods: {
    stringifySlug: UtilService.stringifySlug,
    validateAircraftType: function() {
        this.aircraftType.isValid = this.aircraftTypes.some(type => type.slug === this.aircraftType.value);
    },
    validateRegistration: function() {
      if(!this.registration.value) {
        this.registration.isValid = false;
      } else {
        this.registration.isValid = true;
        this.registration.value = this.registration.value.toUpperCase();
      }
    },
    lookupResident: async function() {
      if(!this.registration.isValid) return;
      const residents = await APIService.getResidents({"fields": {"registration":this.registration.value,"deleted":null}});
      if(residents.length) {
        this.resident = residents[0];
        this.includeResident = true;
      } else {
        this.resident = null;
        this.includeResident = false;
      }
    },
    addAircraft: async function() {
      this.adding = true;
      const residentId = (this.includeResident && this.resident !== null) ? this.resident.resident_id : null;
      await APIService.addAircraft(this.aircraftType.value, this.registration.value, residentId);
      this.aircraftType = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.registration = {
        value: null,
        error: null,
        isValid: undefined
      };
      this.aircraft = await APIService.getAircraft(this.filter);
      this.adding = false;
    },
    async removeAircraft(aircraft) {
      await APIService.deleteAircraft(aircraft.aircraft_id);
      this.aircraft = await APIService.getAircraft(this.filter);
    }
  },
  beforeCreate() {
    APIService.getAircraftTypes()
        .then(aircraftTypes => {
          this.aircraftTypes = aircraftTypes.map(type => {
            return {
              slug: type,
              readable: UtilService.stringifySlug(type)
            }
          });
        });
    APIService.getAircraft(this.filter)
        .then(aircraft => this.aircraft = aircraft);
  }
}
</script>

<style scoped>
tr.aircraft {
  line-height: 2.5;
}

.form-check .form-check-input {
  width: 50%;
  height: 50%;
}
</style>
